import React from "react";
import { HubspotProvider } from "@aaronhayes/react-use-hubspot-form";
import "./src/scss/global.scss";

export const wrapRootElement = ({ element }) => (
  <HubspotProvider>{element}</HubspotProvider>
);

// Fires when scroll position should be updated on any Gatsby-Link based navigation.
// Does not fire on page-refresh unless a scrollPosition is set.
export const shouldUpdateScroll = ({ routerProps, getSavedScrollPosition }) => {
  // Get saved position - this may be [0, 0]
  const [savedX, savedY] = getSavedScrollPosition(routerProps.location);

  // This is only set on "filter" pages. To make it not jump around.
  const { disableScrollUpdate, xPos, yPos } =
    routerProps?.location?.state ?? false;
  if (disableScrollUpdate) {
    // If the saved Y position is not 0 or not equal to the one provided in the route state. Use the saved values.
    // Otherwise use the values provided in the route state
    if (savedY !== 0 && savedY !== yPos) {
      requestAnimationFrame(() => {
        window.scrollTo(savedX, savedY);
      });
    } else {
      requestAnimationFrame(() => {
        window.scrollTo(xPos, yPos);
      });
    }

    return false;
  }

  // Scroll to saved position.
  window.scrollTo(savedX, savedY);

  // In rare case that the saved position was lower than the initial page length, try again.
  if (window.scrollX !== savedX || window.scrollY !== savedY) {
    requestAnimationFrame(() => {
      window.scrollTo(savedX, savedY);
    });
  }

  return false;
};
