exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-study-arsenal-js": () => import("./../../../src/pages/case-study/arsenal.js" /* webpackChunkName: "component---src-pages-case-study-arsenal-js" */),
  "component---src-pages-case-study-nba-js": () => import("./../../../src/pages/case-study/nba.js" /* webpackChunkName: "component---src-pages-case-study-nba-js" */),
  "component---src-pages-savings-calculator-index-js": () => import("./../../../src/pages/savings-calculator/index.js" /* webpackChunkName: "component---src-pages-savings-calculator-index-js" */),
  "component---src-pages-slack-arsenal-index-js": () => import("./../../../src/pages/slack/arsenal/index.js" /* webpackChunkName: "component---src-pages-slack-arsenal-index-js" */),
  "component---src-pages-slack-error-index-js": () => import("./../../../src/pages/slack/error/index.js" /* webpackChunkName: "component---src-pages-slack-error-index-js" */),
  "component---src-pages-slack-success-index-js": () => import("./../../../src/pages/slack/success/index.js" /* webpackChunkName: "component---src-pages-slack-success-index-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-resource-page-resource-page-js": () => import("./../../../src/templates/resourcePage/resourcePage.js" /* webpackChunkName: "component---src-templates-resource-page-resource-page-js" */),
  "component---src-templates-test-page-test-page-js": () => import("./../../../src/templates/testPage/testPage.js" /* webpackChunkName: "component---src-templates-test-page-test-page-js" */)
}

